@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-light: #8abdff;
  --primary: #6d5dfc;
  --primary-dark: #5b0eeb;

  --white: #ffffff;
  --greyLight-1: #e4ebf5;
  --greyLight-2: #c8d0e7;
  --greyLight-3: #bec8e4;
  --greyDark: #9baacf;

  --shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
    -0.2rem -0.2rem 0.5rem var(--white);
  --inner-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
    inset -0.2rem -0.2rem 0.5rem var(--white);
}

html {
  font-family: "Inter", sans-serif;
  background: #e4ebf5;
  background: var(--greyLight-1);
}

.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: #6d5dfc;
  color: var(--primary);
  min-height: 100vh;
}

.AppHeader {
  padding: 60px 0 30px;
  font-weight: 200;
  font-size: 50px;
}

/*  ICONS  */
.icons {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 0.3rem 0.3rem 0.6rem #c8d0e7,
    -0.2rem -0.2rem 0.5rem #ffffff;
  box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
    -0.2rem -0.2rem 0.5rem var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  color: #9baacf;
  color: var(--greyDark);
  transition: all 0.5s ease;
}

.icon img {
  opacity: 0.7;
  transition: opacity 0.2s;
}

.icon:hover img {
  opacity: 1;
}

.icon + .icon {
  margin-left: 20px;
}

.TileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Tile {
  border-radius: 20px;
  box-shadow: 0.8rem 0.8rem 1.4rem #c8d0e7,
    -0.2rem -0.2rem 1.8rem #ffffff;
  box-shadow: 0.8rem 0.8rem 1.4rem var(--greyLight-2),
    -0.2rem -0.2rem 1.8rem var(--white);
  display: flex;
  overflow: hidden;
  margin-bottom: 60px;
  position: relative;
}

canvas {
  border-radius: 20px;
}

